const product = {
  warm: "#D668F7",
  warmRGB: "214, 104, 247",
  cold: "#2A6CD1",
  coldRGB: "42, 108, 209",
} as const;

const service = {
  warm: "#F3ACAA",
  cold: "#ACF6F8",
} as const;

const company = {
  warm: "#F7CEF2",
  cold: "#9DC5F4",
  coldRGB: "157, 197, 244",
} as const;

const neutral = {
  neutral1: "#000",
  neutral2: "#FFF",
  neutral3: "#c8c8c8",
  neutral4: "#bcbcbc",
  neutral2RGB: "255, 255, 255",
  neutralDark: "#717071",
  neutralMedium: "#9E9E9F",
  neutralLight: "#DDDDE1",
} as const;

const noColor = {
  Grey1: "#FAFAFA",
  Grey2: "#F2F2F2",
  Grey3: "#E6E6E6",
  Grey4: "#D9D9D9",
  Grey5: "#BFBFBF",
  Grey6: "#ABABAB",
  Grey7: "#999999",
  Grey8: "#858585",
  Grey9: "#7A7A7A",
  Grey10: "#666666",
  Grey11: "#545454",
  Grey12: "#404040",
  Grey13: "#262626",
  Grey14: "#171717",
  Grey15: "#050505",
  Ash1: "#F2F7FC",
  Ash2: "#E4ECF5",
  Ash3: "#D3DFEB",
  Ash4: "#C3D2E0",
  Ash5: "#B0C1D1",
  Ash6: "#9DAFC2",
  Ash7: "#8B9FB3",
  Ash8: "#7A8FA3",
  Ash9: "#6A7F94",
  Ash10: "#5A6E82",
  Ash11: "#4B5E70",
  Ash12: "#3C4C5C",
  Ash13: "#2E3B48",
  Ash14: "#212A33",
  Ash15: "#161C21",
} as const;

const brandColor = {
  Primary1: "#EAF3F8",
  Primary2: "#DCEBF3",
  Primary3: "#C4DDEB",
  Primary4: "#7FB5CD",
  Primary5: "#5FA2C0",
  Primary6: "#5390AB",
  Primary7: "#457D96",
  Primary8: "#3B6A80",
  Primary9: "#315869",
  Primary10: "#264553",
  Secondary1: "#FEF9EA",
  Secondary2: "#FBECBF",
  Secondary3: "#FAE4A3",
  Secondary4: "#F7D87B",
  Secondary5: "#F5CC51",
  Secondary6: "#F2C028",
  Secondary7: "#D8AB21",
  Secondary8: "#BE961B",
  Secondary9: "#977611",
  Secondary10: "#705607",
} as const;

const themeColor = {
  Airblue: "rgba(227, 236, 243, 0.2)",
  Airblue1: "rgba(227, 236, 243, 0.4)",
  Airblue2: "rgba(227, 236, 243, 0.8)",
  Freshwhite: "#FCFDFE",
  Warmwhite: "#F8F7F6",
  Woodwhite: "#FFFBF8",
  Carbondark: "rgba(47, 49, 66, 0.95)",
  Air1: "rgba(255, 255, 255, 0.80)",
  Air2: "rgba(255, 255, 255, 0.50)",
  Air3: "rgba(255, 255, 255, 0.20)",
  Air4: "rgba(255, 255, 255, 0.05)",
  Fog1: "rgba(0, 0, 0, 0.50)",
  Fog2: "rgba(0, 0, 0, 0.25)",
  Fog3: "rgba(0, 0, 0, 0.15)",
  Fog4: "rgba(0, 0, 0, 0.05)",
  Fog5: "rgba(0, 0, 0, 0.01)",
};

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function rgbToHex(r: number, g: number, b: number): string {
  return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
}

export const textInput = {
  default: noColor.Grey14,
  minor: noColor.Grey8,
  link: brandColor.Primary8,
};

export const buttonInput = {
  default: noColor.Ash12,
  disabled: noColor.Grey8,
};

export const baseColor = {
  Green1: "#E8F5EB",
  Green2: "#C9E9D1",
  Green3: "#98D3A4",
  Green4: "#4AB765",
  Green5: "#149B42",
  Green6: "#00782B",
  Green7: "#005917",
  Green8: "#004712",
  Red1: "#FCEEEE",
  Red2: "#F9CCCB",
  Red3: "#F49A97",
  Red4: "#F07875",
  Red5: "#EC5652",
  Red6: "#C34642",
  Red7: "#993532",
  Red8: "#702523",
  Blue1: "#DCEBF3",
  Blue2: "#ABCFEF",
  Blue3: "#80B3DE",
  Blue4: "#5696CE",
  Blue5: "#2B7ABD",
  Blue6: "#105DA1",
  Blue7: "#054985",
  Blue8: "#023663",
  Lime1: "#F1F9E1",
  Lime2: "#D9EDAB",
  Lime3: "#CAE589",
  Lime4: "#BBDE68",
  Lime5: "#ACD746",
  Lime6: "#94B93B",
  Lime7: "#7C9C30",
  Lime8: "#576F1F",
  Cyan1: "#E8F5F4",
  Cyan2: "#D1EBE9",
  Cyan3: "#A2D6D4",
  Cyan4: "#8BCCC9",
  Cyan5: "#72AAA7",
  Cyan6: "#598885",
  Cyan7: "#406564",
  Cyan8: "#345453",
  Volcano1: "#FCE6DC",
  Volcano2: "#F8CDBA",
  Volcano3: "#F5B397",
  Volcano4: "#F19A75",
  Volcano5: "#EE8152",
  Volcano6: "#C36942",
  Volcano7: "#985132",
  Volcano8: "#6C3822",
  Sunset1: "#FDEFDD",
  Sunset2: "#FADEBB",
  Sunset3: "#F8CE9A",
  Sunset4: "#F5BD78",
  Sunset5: "#F3AD56",
  Sunset6: "#C98E45",
  Sunset7: "#9E6F34",
  Sunset8: "#745024",
  Purple1: "#F2EFF7",
  Purple2: "#E5E0EE",
  Purple3: "#CCC0DE",
  Purple4: "#B2A1CD",
  Purple5: "#9981BD",
  Purple6: "#7F62AC",
  Purple7: "#664E8A",
  Purple8: "#4C3B67",
  Magenta1: "#FAE5F0",
  Magenta2: "#F4CBE1",
  Magenta3: "#EFB1D1",
  Magenta4: "#E997C2",
  Magenta5: "#E47DB3",
  Magenta6: "#C06695",
  Magenta7: "#9C4F77",
  Magenta8: "#77385A",
} as const;

export const colors = {
  product,
  service,
  company,
  ...neutral,
  noColor,
  brandColor,
  themeColor,
  baseColor,
  textInput,
  buttonInput,
} as const;
