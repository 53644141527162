type TFontsStyles = Pick<
  React.CSSProperties,
  "fontFamily" | "fontSize" | "fontWeight" | "lineHeight" | "letterSpacing"
>;

const navFontFamily = `'Be Vietnam Pro'`;
const titleFontFamily = `'DM Mono'`;
const fontFamily = `'pp_neue_montreal'`;
const navFonts = {
  fontFamily: navFontFamily,
  fontWeight: "bold",
  lineHeight: 1,
} as const satisfies TFontsStyles;
/** @description only used in product modal */
const sloganIndex = {
  fontFamily: titleFontFamily,
  fontSize: 28,
  lineHeight: 1.5,
  letterSpacing: 0,
} as const satisfies TFontsStyles;
/** @description only used in product modal */
const slogan = {
  fontFamily,
  fontWeight: "normal",
  fontSize: 28,
  lineHeight: 1.3,
  letterSpacing: 0.06,
} as const satisfies TFontsStyles;
const title = {
  fontFamily: titleFontFamily,
  fontSize: 24,
  fontWeight: 300,
  lineHeight: 1.5,
  letterSpacing: 0,
} as const satisfies TFontsStyles;
const paragraph = {
  fontFamily,
  fontWeight: "normal",
  fontSize: 30,
  lineHeight: 1.3,
  letterSpacing: 0.06,
} as const satisfies TFontsStyles;

export const fontStyles = {
  title,
  slogan,
  sloganIndex,
  paragraph,
  navFonts,
} as const;

const createFont = <T extends TFontsStyles>(font: T) =>
  `
  font-family: ${font.fontFamily};
  font-size: ${font.fontSize}px;
  font-weight: ${font.fontWeight};
  line-height: ${font.lineHeight};
  ${
    typeof font.letterSpacing !== "undefined"
      ? `letter-spacing: ${font.letterSpacing}em;`
      : ""
  }
` as unknown as typeof font;

export const fonts = {
  title: createFont(title),
  slogan: createFont(slogan),
  sloganIndex: createFont(sloganIndex),
  paragraph: createFont(paragraph),
  navFonts: createFont(navFonts),
} as const;

/** @figma https://www.figma.com/design/JGFHYME2p7RpeMYcoMqooS/ODE-Library-1.0?node-id=4387-8036&t=YnjFzYSDbthmqPgd-0 */
export const fontFamilyMain = `"Manrope", "SF Pro", "Roboto", "Helvetica Neue", sans-serif`;
export const bold = {
  Medium: 500,
  SemiBold: 600,
} as const;

export const mainFonts = {
  "H1/Regular": {
    fontFamily: fontFamilyMain,
    fontSize: 36,
    lineHeight: 1.56,
  },
  "H1/Bold": {
    fontFamily: fontFamilyMain,
    fontSize: 36,
    lineHeight: 1.56,
    fontWeight: bold.SemiBold,
  },
  "H2/Regular": {
    fontFamily: fontFamilyMain,
    fontSize: 32,
    lineHeight: 1.5,
  },
  "H2/Bold": {
    fontFamily: fontFamilyMain,
    fontSize: 32,
    lineHeight: 1.5,
    fontWeight: bold.SemiBold,
  },
  "H3/Regular": {
    fontFamily: fontFamilyMain,
    fontSize: 24,
    lineHeight: 1.5,
    fontWeight: bold.Medium,
  },
  "H3/Bold": {
    fontFamily: fontFamilyMain,
    fontSize: 24,
    lineHeight: 1.5,
    fontWeight: bold.SemiBold,
  },
  "H4/Regular": {
    fontFamily: fontFamilyMain,
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: bold.Medium,
  },
  "H4/Bold": {
    fontFamily: fontFamilyMain,
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: bold.SemiBold,
  },
  "Body-Large/Regular": {
    fontFamily: fontFamilyMain,
    fontSize: 18,
    lineHeight: 1.56,
    fontWeight: bold.Medium,
  },
  "Body-Large/Bold": {
    fontFamily: fontFamilyMain,
    fontSize: 18,
    lineHeight: 1.56,
    fontWeight: bold.SemiBold,
  },
  "Body-Medium/Regular": {
    fontFamily: fontFamilyMain,
    fontSize: 14,
    lineHeight: 1.43,
    fontWeight: bold.Medium,
  },
  "Body-Medium/Bold": {
    fontFamily: fontFamilyMain,
    fontSize: 14,
    lineHeight: 1.43,
    fontWeight: bold.SemiBold,
  },
  "Caption-Large/Regular": {
    fontFamily: fontFamilyMain,
    fontSize: 12,
    lineHeight: 1.33,
  },
  "Caption-Large/Bold": {
    fontFamily: fontFamilyMain,
    fontSize: 12,
    lineHeight: 1.33,
    fontWeight: bold.SemiBold,
  },
  "Caption-Medium/Regular": {
    fontFamily: fontFamilyMain,
    fontSize: 10,
    lineHeight: 1.6,
  },
  "Caption-Medium/Bold": {
    fontFamily: fontFamilyMain,
    fontSize: 10,
    lineHeight: 1.6,
    fontWeight: bold.SemiBold,
  },
  "Display-1": {
    fontFamily: fontFamilyMain,
    fontSize: 80,
    lineHeight: 1.1,
    letterSpacing: "-0.012em",
  },
  "Display-2": {
    fontFamily: fontFamilyMain,
    fontSize: 60,
    lineHeight: 1.1,
    letterSpacing: "-0.012em",
  },
  "Display-3": {
    fontFamily: fontFamilyMain,
    fontSize: 42,
    lineHeight: 1.1,
  },
} as const satisfies Record<string, TFontsStyles>;
