import { fonts, fontStyles, mainFonts } from "./fonts";
import { colors } from "./colors";
import { commons } from "./commons";
import { zIndexes } from "./zIndexes";

export const theme = {
  fonts,
  mainFonts,
  fontStyles,
  colors,
  ...commons,
  zIndexes,
} as const;
